import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdx-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "registers"
    }}>{`Registers`}</h3>
    <p>{`Exas have 4 accessible registers:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`REG`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`X`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General-purpose storage, stores a number or keyword`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`T`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Also a general-purpose register, however `}<inlineCode parentName="td">{`TEST`}</inlineCode>{` writes to this register, and `}<inlineCode parentName="td">{`TJMP`}</inlineCode>{` and `}<inlineCode parentName="td">{`FJMP`}</inlineCode>{` also read from this register`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Refers to an exa's held file. Reading from or writing to F advances the "file cursor" by one. Writing to F will overwrite a value unless the cursor is at the end of the file`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used for communication (locally or globally) between Exas. Reading and writing to this register will cause the Exa to wait for another Exa to either write or read the `}<inlineCode parentName="td">{`M`}</inlineCode>{` register too`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Hardware registers starting with `}<inlineCode parentName="p">{`#`}</inlineCode>{` can also be accessed when an Exa is in the same node`}</p>
    <h3 {...{
      "id": "instructions"
    }}>{`Instructions`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`INST`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Syntax`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NOOP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`NOOP`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A no-op`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NOTE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`NOTE [...]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A comment line, `}<inlineCode parentName="td">{`;`}</inlineCode>{` also works`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`COPY`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`COPY {REG/NUM} {REG}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Writes value of {REG/NUM} to {REG}`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ADDI`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ADDI {REG/NUM} {REG/NUM} {REG}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Adds the first {REG/NUM} to the second and writes it to {REG}`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SUBI`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`SUBI {REG/NUM} {REG/NUM} {REG}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Subtracts the first {REG/NUM} from the second and writes it to {REG}`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`MULI`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`MULI {REG/NUM} {REG/NUM} {REG}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Multiplies the first {REG/NUM} by the second and writes it to {REG}`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DIVI`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`DIVI {REG/NUM} {REG/NUM} {REG}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Divides the first {REG/NUM} by the second and writes it (rounded) to {REG}`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`MODI`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`MODI {REG/NUM} {REG/NUM} {REG}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Divides the first {REG/NUM} by the second and writes the remainder to {REG}`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`RAND`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`RAND {REG/NUM} {REG/NUM} {REG}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Generates random number between the first {REG/NUM} and the second and writes to {REG}`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SWIZ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`SWIZ {REG/NUM} {REG/NUM} {REG}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Takes first {REG/NUM} and uses second as a mask to generate a "Swizzled" value (more details below), writes to {REG}`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`TEST`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`TEST {REG/NUM} {=/>/<} {REG/NUM}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Compares the two input values and writes to register `}<inlineCode parentName="td">{`T`}</inlineCode>{` (1 if true, 0 if false)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`TEST MRD`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Checks if Exa could read from another Exa without waiting, and writes to register `}<inlineCode parentName="td">{`T`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`TEST EOF`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Checks if file cursor is at the end of the held file, and writes to register `}<inlineCode parentName="td">{`T`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`MARK`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`MARK {LABEL}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sets a label (pseudo-instruction)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`JUMP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`JUMP {LABEL}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Jumps to {LABEL}`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`TJMP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`TJMP {LABEL}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If `}<inlineCode parentName="td">{`T`}</inlineCode>{` != 0, jumps to {LABEL}`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`FJMP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`FJMP {LABEL}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If `}<inlineCode parentName="td">{`T`}</inlineCode>{` == 0, jumps to {LABEL}`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`REPL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`REPL {LABEL}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Creates a copy of this Exa (including location and registers, but not file), starting execution from {LABEL}`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HALT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`HALT`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Terminates Exa and drops held file`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`KILL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`KILL`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Terminates `}<em parentName="td">{`other`}</em>{` Exa, random but prioritises own Exas`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`LINK`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`LINK {REG/NUM}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Moves through link with ID of {REG/NUM}`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HOST`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`HOST {REG}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Copies hostname to {REG}`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`MODE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`MODE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Toggles `}<inlineCode parentName="td">{`M`}</inlineCode>{` register between global and local`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`VOID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`VOID M`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Discards value from `}<inlineCode parentName="td">{`M`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`MAKE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`MAKE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Creates and grabs a new file`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`GRAB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`GRAB {REG/NUM}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Grabs file with ID of {REG/NUM}`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`FILE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`FILE {REG}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Copies held file ID into {REG}`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SEEK`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`SEEK {REG/NUM}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Moves file cursor by {REG/NUM}, clamped between start and end of file (-9999 and 9999 will reliably get you to the start and end of a file)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`VOID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`VOID F`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Removes value in `}<inlineCode parentName="td">{`F`}</inlineCode>{` where file cursor is pointing`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DROP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`DROP`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Drops held file`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`WIPE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`WIPE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Deletes held file`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "macros"
    }}>{`Macros`}</h3>
    <p>{`Macros are shorthand for writing repeating statements starting with `}<inlineCode parentName="p">{`@REPL N`}</inlineCode>{` (`}<inlineCode parentName="p">{`N`}</inlineCode>{` being the number of loops) and ending `}<inlineCode parentName="p">{`@END`}</inlineCode>{`, within a macro, `}<inlineCode parentName="p">{`@{X,Y}`}</inlineCode>{` can be used, `}<inlineCode parentName="p">{`X`}</inlineCode>{` being the first value used, and `}<inlineCode parentName="p">{`Y`}</inlineCode>{` being the amount added to `}<inlineCode parentName="p">{`X`}</inlineCode>{` on each loop, so `}<inlineCode parentName="p">{`@{0,3}`}</inlineCode>{` would be 0 on loop 1, 3 on the seconds, 6 on the third and so on. These can be used in label names too, which can be combined with testing and jumping to certain labels.`}</p>
    <p>{`NOTE: Macros are not actual loops and can result in more instructions than a "real" loop`}</p>
    <h3 {...{
      "id": "swizzling"
    }}>{`Swizzling`}</h3>
    <p>{`The mask digits represent a reversed index (from 1) of the input number (so 4321 will return the input number), and 0 will always give a 0. A negative mask will produce a negative number (or positive if input is negative).`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`IN`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`MASK`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`OUT`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6789`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4321`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6789`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6789`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1234`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`9876`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6789`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3333`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`7777`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6789`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1211`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`9899`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6789`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-4321`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-6789`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`-6789`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-4321`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6789`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6789`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8000`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6789`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0001`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0009`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "highway-signs"
    }}>{`Highway Signs`}</h4>
    <p>{`When writing to highway signs, it takes 3 values to write a character to a highway sign:`}</p>
    <ul>
      <li parentName="ul">{`0-indexed row,`}</li>
      <li parentName="ul">{`0-indexed column,`}</li>
      <li parentName="ul">{`then the character.`}</li>
    </ul>
    <p>{`TOP TIP: when looping through a message, you can get row by dividing x by row length, and column by modulo-ing by row length.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      